import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  StyledAboutGift,
  AboutGiftContent
} from "../../styledComponents/gift"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import HeroBanner from "../../components/HeroBanner"
import { HeroText, HeroHeader } from "../../styledComponents/heroBanner"
import Icon from "../../components/icon"
import {StyledBlockQuote} from "../../styledComponents/base"
import EventList from "../../components/EventList"
import {
  Section,
  SectionHeading
} from "../../styledComponents/section"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Gift = ({ data, location }) => {
    
    return (
      <Layout location={location} isHomePage>
        <SEO title="Karina Grant - Energy healing" Description="Karina Grant is a energy and chakra healer working from the world renowned Hale Clinic in London." />
        <HeroBanner image="/images/lightspace-background.jpg" type="lightspace">
            <HeroText>
              <HeroHeader isLightSpace='true'>
              <strong>Collective LightSpace</strong>
              </HeroHeader>
              {/* <HeroDescription isGift='true'>Speaking from my experience of teaching and helping others...</HeroDescription>
              <HeroDescription isGift='true'>The greatest joy in the world comes from helping another human being to experience higher levels of happiness.</HeroDescription> */}
              <StyledBlockQuote >
              <Icon name="singlequote" />
              <p>Sending altruistic thoughts of healing in a group setting is a “fast track to the miraculous” or "passport to ecstatic oneness.”</p>
              {/* <img src="/images/lynne-mctaggart.jpg" alt="Photo of Lynne McTaggart"/> */}
              <cite>Lynne McTaggart</cite>
            </StyledBlockQuote> 
            <AnchorLink to="/quantum-touch/collective-lightspace#upcoming-sessions">Upcoming dates</AnchorLink>
            <AnchorLink to="/quantum-touch/collective-lightspace#learn">Learn more</AnchorLink>
            </HeroText>
        </HeroBanner>

        <StyledAboutGift itemScope itemType="http://schema.org/Article" id="learn">
          <AboutGiftContent itemProp="articleBody">
            <p>Lynne’s amazing research is based on focused collective healing intention. Now imagine what happens when we bring Quantum-Touch into the flow. </p>
            <p><strong>The LightSpace is a place for us to focus on specific intentions and also harness our own healing skills - together.</strong></p>

            <p>You will remember that the energy in our workshops is always very high because this is what automatically happens in a group. It is a Universal Law. </p>

            <p>The power of group prayer is clearly present in many ancient spiritual traditions that are still widely practiced today. </p>

            <p>In our sessions we will:</p>

            <ul>
              <li>Energise our powerful group intentions</li>
              <li>Give/receive group and paired healing</li>
              <li>Practice your healing skills in a guided setting</li>
              <li>Ask questions about QT techniques</li>
              <li>Refresh your skillset</li>
            </ul>
            
            <p>Please only attend the sessions if you have taken that specific workshop.</p>
            {/* <h3>LightSpace booking</h3> */}
            {/* <LightSpacePurchase /> */}

            <p>The more frequently we can meet and vibe together - the higher we collectively rise.</p>
            {/* <InlineWidget 
              pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: true,
                hideLandingPageDetails: true,
                primaryColor: '9c27b0',
                textColor: '242220'
              }}
              url="https://calendly.com/karinagranthealing/collective?month=2021-11&date=2021-11-10" 
            /> */}
          </AboutGiftContent>
        </StyledAboutGift>

        

        {/* <Section>
            <Heading level={1} align="center">
              {giftData[0].title}
              <GiftList posts={giftData[0].gifts} />
            </Heading>
          </Section> */}
      </Layout>
    )
}

export default Gift


export const pageQuery = graphql`
  query {
      
    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`

