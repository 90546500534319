import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";

const StyledAboutGift = styled.article`
    ${baseGridStyles}
    h2 {
        text-align: center;
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 2 / 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }

        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 4 / 10;
        }
    }
    p{
        margin-top: 1.6rem;
    }
`
const AboutGiftContent = styled.div`
    grid-column: span 4;

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: 4 / 10;
    }

    p {
        margin-top: 1.6rem;
    }

    h2 {
        margin-top: 4rem;
        margin-bottom: 0.8rem;        
        @media screen and (min-width: ${breakpoints.l}px) {
            margin-top: 5.2rem;
            margin-bottom: 2rem;
        }
    }

    blockquote {
        background: var(--light-purple-mid);
        margin-bottom: 2.4rem;

        @media screen and (min-width: ${breakpoints.l}px) {
            width: 100%;
            margin-bottom: 4.8rem;
        }

        a {
          text-decoration: none;
          border-bottom: 0;
          color: #242220;
        }

        p{
            @media screen and (min-width: ${breakpoints.l}px) {
            max-width: 450px;
            }
        }
    }

    .gatsby-image-wrapper {
        margin-top: 1.6rem;
    }

    ul {
        
    }
    
`

export {
    StyledAboutGift,
    AboutGiftContent
}